import React, { useState } from "react";
import useGetPayloadData, { useGetPayloadDataAx } from "hooks/useGetPayloadData";
import TableRow from "./TableRow";
import { chainIdentifiers } from "../../../../config";

const PayloadTable = () => {
  const [checked, setChecked] = useState(false);
  const data = useGetPayloadData(checked);
  // const data = [];
  const data1 = useGetPayloadDataAx();
  const handleChange = () => {
    setChecked(!checked);
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center aggregator-title">
        <div>Payload Status LZ</div>
        <label>
          <input className="check-box" type="checkbox" checked={checked} onChange={handleChange}/>
          Not Cleared Only
        </label>
      </div>

      {data ? (
        <div className="aggregator-value-wrapper">
          <div className="aggregator-value-container">
            <table className="aggregator-table">
              <thead>
                <tr>
                  <th style={{ width: 200 }}>Chain</th>
                  <th>Txn Hash</th>
                  <th>Source Chain Id</th>
                  <th>Source Address</th>
                  <th>Nonce</th>
                  <th>Cleared</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                
              {Object.keys(data).map((key) => {
                return data[key].map((item, index) => {
                  return <TableRow item={{ ...item, chainId: key }} key={`${key}-${index}`} />;
                });
              })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="d-flex empty-card text">Loading...</div>
      )}

      <div className="d-flex justify-content-between align-items-center aggregator-title">
        <div>Payload Status Axelar</div>
      </div>
      {data1 ? (
        <div className="aggregator-value-wrapper">
          <div className="aggregator-value-container">
            <table className="aggregator-table">
              <thead>
                <tr>
                  <th style={{ width: 200 }}>Chain</th>
                  <th>Txn Hash</th>
                  <th>Source Chain Id</th>
                  <th>Source Address</th>
                  <th>Nonce</th>
                  <th>Cleared</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                
              {data1.map((item, key) => {
                  const txn = item.call.transaction;
                  // chain id depending on the error
                  // Check if there is an error and what's the network of it
                  let chainId;
                  if (item?.error?.chain) {
                    chainId = Object.keys(chainIdentifiers).find(key =>
                      chainIdentifiers[key] === item.error.chain);
                  } else {
                    chainId = txn.chainId;
                  }
                  return <TableRow item={{ data: item, axelar: true, chainId, srcChainId: txn.chainId, txn: txn.hash, srcAddress: txn.from, nonce: txn.nonce, cleared: false }} key={`${key}`} />;
              })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="d-flex empty-card text">Loading...</div>
      )}
    </div>
  );
};

export default PayloadTable;
