import { Tooltip } from "react-tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getImageUrl } from "utils";
import { getBalanceWithComma, getShortenValue } from "utils/number";
import { AVAILABLE_NETWORKS } from "config";

const TtSupplyTbRow = ({ item }) => {
  return (
    <tr>
      <td className="total-supply-name">
        <img src={getImageUrl(item.symbol)} alt="" className="token-icon" />
        <div> {item.symbol} </div>
      </td>
      {AVAILABLE_NETWORKS.map((chainId) => {
        const convertedFullValue = getBalanceWithComma(
          item.totalSupply[chainId].toString()
        );
        return (
          <td className="values" key={`key-${chainId}`}>
            <CopyToClipboard text={convertedFullValue}>
              <span data-tooltip-id={`${item.symbol}-${chainId}`}>
                {getShortenValue(convertedFullValue)}
              </span>
            </CopyToClipboard>
            <Tooltip
              id={`${item.symbol}-${chainId}`}
              place="top"
              content={convertedFullValue}
            />
          </td>
        );
      })}
      <td className="values">
        <CopyToClipboard text={getBalanceWithComma(item.sum.toString())}>
          <span> {getBalanceWithComma(item.sum.toString())} </span>
        </CopyToClipboard>
      </td>
      <td
        className={
          (item.sum == item.aggrValue) ? "values" : "values wrong-value"
        }
      >
        <CopyToClipboard text={getBalanceWithComma(item.aggrValue.toString())}>
          <span>{getBalanceWithComma(item.aggrValue.toString())} </span>
        </CopyToClipboard>
      </td>
    </tr>
  );
};

export default TtSupplyTbRow;
