import { useEffect, useState } from "react";
import { getFailedMsgData, getStoredPayloadData, getFailedStoredPayloadData, getNotClearedFailedMsgData } from "utils/graphHelper";
import { AVAILABLE_NETWORKS, chainIdentifiers } from "../config";
import contracts from "../config/contracts";
import axios from "axios";

const useGetPayloadData = (cleard = false) => {
  const [loadedData, setLoadedData] = useState(null);

  useEffect(() => {
    const get = async () => {
      const calls = AVAILABLE_NETWORKS.map((id) => {
        return cleard ? getFailedStoredPayloadData(id) : getStoredPayloadData(id);
      });
      const values = await Promise.all(calls).then((value) => {
        return value;
      });
      let tempData = {};
      AVAILABLE_NETWORKS.forEach((id, index) => {
        tempData[id] = values[index];
      });
      setLoadedData(tempData);
    };
    get();
  }, [cleard]);
  return loadedData;
};

export const useGetFailedMsgData = (cleared = false) => {
  const [loadedData, setLoadedData] = useState(null);

  useEffect(() => {
    const get = async () => {
      const calls = AVAILABLE_NETWORKS.map((id) => {
        return cleared ? getNotClearedFailedMsgData(id) : getFailedMsgData(id);
      });
      const values = await Promise.all(calls).then((value) => {
        return value;
      });
      let tempData = {};
      AVAILABLE_NETWORKS.forEach((id, index) => {
        tempData[id] = values[index];
      });
      setLoadedData(tempData);
    };
    get();
  }, [cleared]);
  return loadedData;
};

export const useGetPayloadDataAx = (cleared = false) => {
  const [loadedData, setLoadedData] = useState(null);
  useEffect(() => {
    const get = async () => {
        try {
          const __data = await getAllAXFailedMessage();
          console.log("DATA =>", __data);
          setLoadedData(__data);
        } catch (error) {
          console.error("Error:", error);
        }
    };
    get();
  }, [cleared]);
  return loadedData;
};

async function getAllAXFailedMessage () {
  try {
    let _transactions = [];
    const FailedTransactionStatus = {
      ERROR: "error",
      INSUFFICIENT_FEE: "insufficient_fee",
      CALLED: "called",
    };
    const axelarBridge = contracts.SynthrBridgeAxelar;
    for (const sourceChain in chainIdentifiers) {
      for (const destChain in chainIdentifiers) {
        if (sourceChain === destChain) {
          continue;
        }
        if (Number(sourceChain) === 421614 || Number(destChain) === 421614) {
          for (const status in FailedTransactionStatus) {
            const txns = await getBridgeFailedMessages(
              axelarBridge[sourceChain],
              axelarBridge[destChain],
              FailedTransactionStatus[status],
              chainIdentifiers[sourceChain],
              chainIdentifiers[destChain],
              190,
              0
            )
            _transactions.push(...txns);
          }
        }
      }
    }
    return _transactions;
  } catch (error) {
    console.log("ERROR: ", error);
  }
}
async function getBridgeFailedMessages (sourceAddress, contractAddress, status, sourceChain, destinationChain, size, from) {
  let hasMore = true;
  const api = "https://testnet.api.axelarscan.io/gmp/searchGMP";
  const data = {
    sourceAddress,
    contractAddress,
    status: status,
    sourceChain,
    destinationChain,
    size,
    from
  };
  let items = [];
  while (hasMore) {
    const response = await axios.post(api, data);
    items = [...items, ...response.data.data];
    if (response.data.data.length === size) {
      from += size;
      await new Promise((resolve) => setTimeout(resolve, 2000));
    } else {
      hasMore = false;
      console.log("No more transactions to fetch...");
    }
  }
  const filteredTransactions = await getFilteredTransactions(items);
  return filteredTransactions;
}

async function getFilteredTransactions(array) {
  const filteredArray = array.filter(
    (item) =>
      item.is_invalid_call || item.is_invalid_gas_paid || item.confirm_failed || item.status == "error"
    // item.is_invalid_amount ||
    // item.is_invalid_symbol ||
    // item.is_invalid_destination_chain ||
    // item.is_invalid_contract_address ||
    // item.is_invalid_source_address ||
    || item.is_insufficient_fee
  );
  return filteredArray;
}

export default useGetPayloadData;
